import {useEffect, useMemo} from "react";

const GatewayPage = () => {

    const cookieName = 'TradeOffAuthUser';
    const cookieValue = true;
    const myDate = useMemo(() => new Date(), []);
    myDate.setMonth(myDate.getMonth() + 1);
    useEffect(() => {
        document.cookie = `${cookieName}=; path=/; domain=.tradeoff.com; expires=` + new Date(0).toUTCString();
        document.cookie = cookieName +"=" + cookieValue + ";expires=" + myDate.toGMTString()
            + ";domain=.tradeoff.com;path=/";
    }, [cookieValue, myDate, cookieName])

    return <>Build Completed</>
};

export default GatewayPage;