import investStyles from './invest.module.css';


const InvestPage = () => {
  return (
    <div className={`${investStyles.wrapper} container-fluid`}>
      <div className={`container ${investStyles.container}`}>
        <div className={`${investStyles.containerInner}`}>
          {/* Invest In logo and text */}
          <div className={`row p-2`}>
            <div className={`col-md-5 ${investStyles.textRight}`}>
              <h1 className={`${investStyles.h1}`}>Invest In</h1>
            </div>
            <div className={`col-md-7`}>
              <img alt='TradeOff Logo' src='https://stdisclosureslegal.blob.core.windows.net/invest/logo-medium-light.png' className={`${investStyles.logo}`} />
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-12`}>
              <h2 className={`${investStyles.h2}`}>
                Limited $1 Million Investment Round for Accredited Investors Only
              </h2>
            </div>
            <div className={`col-12`}>
              <div className={`${investStyles.videoWrapper}`}>
                <iframe width="100%" height="409" src="https://www.youtube.com/embed/5-aNYJx98cE" title="TradeOff" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`container-fluid`} style={{backgroundColor:'#fff', paddingBottom:'60px'}}>
        <div className={`${investStyles.containerInner2}`}>
          <div className={`row`}>
            <div className={`col-md-8`}>
              <h1>Ready to Invest</h1>
              <h2>TradeOff is offering a limited investment opportunity to Accredited Investors only through an SPV (Special Purpose Vehicle) hosted on Glassboard.</h2>
              <h2>Once this limited $1 million Investment Round is full it will be closed to new investments.</h2>
              <h2>TO INVEST click the INVEST NOW button and register on Glassboard to get access to the TradeOff SPV investment.</h2>
              <h2>To Invest, you will need the following...</h2>
              <ul className={`${investStyles.elBulletList} fa-ul`}>
                <li>
                  <span class="fa-li"><i class="fa-solid fa-check"></i></span>
                  Uploadable picture of both front & back of Photo ID (Driver's License, Passport or Government ID)
                </li>
                <li>
                  <span class="fa-li"><i class="fa-solid fa-check"></i></span>
                  Bank details for the bank you are sending your wire from (Bank Name, Name on Account, Account Number)
                </li>
                <li>
                  <span class="fa-li"><i class="fa-solid fa-check"></i></span>
                  Social Security Number
                </li>
              </ul>
            </div>
            <div className={`col-md-4 pt-5 text-center`}>
              <img alt='TradeOff application running on tablet' src='https://stdisclosureslegal.blob.core.windows.net/invest/tablet-01.png' width={'100%'}/>
              <a href="https://app-glassboard2.assure.co/invest-now?deal=62b9c485a0f5315fdf38153b" className={`btn btn-warning ${investStyles['btn-xl']}`} target='_blank' rel="noopener noreferrer">
                <span class="elButtonMain">Invest Now</span>
                <span class="elButtonSub"></span>
              </a>
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className={`${investStyles.divider}`} />
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-md-12`}>
              <h1>More Information</h1>
              <h2>FOR MORE INFORMATION about the TradeOff investment click TradeOff Investment Process & Description PDF or click here Schedule a Call with Tradeoff.</h2>
            </div>
            <div className={`col-md-8`}>
              <h2><span style={{color: 'rgb(0, 117, 178)', fontWeight:'bold'}}>FOR MORE INFORMATION</span> about the TradeOff investment click TradeOff Investment Process Description PDF.</h2>
            </div>
            <div className={`col-md-4`}>
              <a href="https://stdisclosureslegal.blob.core.windows.net/invest/TradeOff-Investment-Description2.0-2.pdf" target={'_blank'} className={`btn btn-warning ${investStyles['btn-md-blue']}`} rel="noopener noreferrer">
                <span class="elButtonMain">TradeOff Investment<br/>Process PDF</span>
                <span class="elButtonSub"></span>
              </a>
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className={`${investStyles.divider}`} />
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-md-8`}>
              <h2><span style={{color: 'rgb(0, 117, 178)', fontWeight:'bold'}}>Video : How to Invest on Glassboard</span></h2>
            </div>
            <div className={`col-md-4`}>
              <a href="https://help.assure.co/knowledge/getting-started-with-glassboard/video-the-investor-experience" target={'_blank'} className={`btn btn-warning ${investStyles['btn-md-blue']}`} rel="noopener noreferrer">
                <span class="elButtonMain">Watch Video</span>
                <span class="elButtonSub"></span>
              </a>
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className={`${investStyles.divider}`} />
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-md-12 ${investStyles.linkContainer}`}>
              <p style={{lineHeight:1.4}}>Questions or Issues contact customer support at <a href='tel:+18599633445'>(859) 963-3445</a>, <a href='tel:+17202487480'>(720) 248-7480</a> or <a href='mailto:info@tradeoff.com'>info@tradeoff.com</a></p>
            </div>
          </div>
        </div>
      </div>

      <div className={`container-fluid`} style={{backgroundColor:'rgb(93, 93, 93)', padding:'50px', textAlign:'center'}}>
        <div className='row'>
          <div className={`col-md-12 ${investStyles.textCenter}`}>
            <p>© 2022 TradeOff Financial Corp. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvestPage;
