import { Route, Routes } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import './App.css';


import HomePage from './pages/home';
import PrivacyPage from './pages/privacy';
import InvestPage from './pages/invest';
import GatewayPage from "./pages/gateway";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  
  return( <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/privacy-policy' element={<PrivacyPage />} />
            <Route path='/invest' element={<InvestPage />} />
            <Route path='/entry/e73f1ece5087b8a5ae33998952202202' element={<GatewayPage />} />
          </Routes>);
}

export default App;
