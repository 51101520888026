import axios from "axios";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import ProductImage from "../images/background.png";
import SmallLogoImage from "../images/logo.png";
import homeStyles from "./home.module.css";

const REGISTRATION_URI =
  "https://func-landingapi-prod.azurewebsites.net/api/registration/get-early-access";

const HomePage = () => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [email, setEmail] = useState("");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submitEmail = async () => {
    let isValidEmail = validateEmail(email);

    if (!isValidEmail) return alert("Invalid email");

    setSubmissionInProgress(true);
    const data = { emailAddress: email };
    axios
      .post(REGISTRATION_URI, JSON.stringify(data), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((r) => {
        // console.log(r);
        setEmailSubmitted(true);
        setSubmissionInProgress(false);
      })
      .catch((e) => {
        // console.log(e);
        setEmailSubmitted(false);
        setSubmissionInProgress(false);
      });
  };

  // Disable this if we don't want signup form
  const showEmailSignUp = true;
  return (
    <>
      <div className={homeStyles.mainContainer}>
        <div className={homeStyles.leftCoulmn}>
          
          <div className={homeStyles.container}>
            <h1 className={homeStyles.title}>TRADEOFF</h1>
            <h2 className={homeStyles.intro}>
              Where the next generation of
              <br />
              traders & investors win, together!
            </h2>

            <div className={homeStyles.signupbox}>
              <h4 className={homeStyles.tag}>
                <span>Coming Soon!</span>Sign up for early access.
              </h4>
              {showEmailSignUp && !emailSubmitted && !submissionInProgress && (
                <div className={homeStyles.form}>
                  <div className={homeStyles.button} onClick={submitEmail}>
                    Sign Up
                  </div>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              )}
              {submissionInProgress && (
                <div className={homeStyles.ringPreloader}></div>
              )}
              <div style={{ display: "flex", flexDirection: "row", justifyContent:'center', alignItems:'center' }}>
                <p className={homeStyles.muted}>
                  By using this form you agree with our{" "}
                  <NavLink to="/privacy-policy">privacy policy</NavLink>.
                </p>
                <img
                  className={homeStyles.smallLogo}
                  src={SmallLogoImage}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div  className={homeStyles.rightColumn}>
          <img src={ProductImage} className={homeStyles.appgui} alt="" />
        </div>
      </div>
      <p className={homeStyles.copyright}>
        &copy; 2023 TradeOff Financial, LLC.
      </p>
    </>
  );
};

export default HomePage;
